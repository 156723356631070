<template>
  <a-layout id="components-layout-demo-side" style="width: 100%;height: 100%">


    <a-layout-sider v-model="collapsed" collapsible>
      <div style="display: flex;justify-content: center;align-items: center"
           @click="$router.push('/personality/home')">
        <div style="width: 100%;height: 52px;margin-top: 6px">
          <img src="@/assets/logo.png" alt="" height="100%"/>
        </div>


      </div>

      <a-menu
          theme="dark" :default-selected-keys="[$route.path]" mode="inline"
          style="height: 92%"
      >

        <a-sub-menu key="sub1">
          <span slot="title"><a-icon type="control"/><span>网页</span></span>
          <a-menu-item key="/BackEnd/webInfo" @click="$router.push('/BackEnd/webInfo')">
            <span>网站信息设置</span>
          </a-menu-item>
          <a-menu-item key="/BackEnd/Guns" @click="$router.push('/BackEnd/Guns')">
            <span>枪械排序设置</span>
          </a-menu-item>
          <a-menu-item key="/BackEnd/customGuns" @click="$router.push('/BackEnd/customGuns')">
            <span>物品自定组合</span>
          </a-menu-item>


          <a-menu-item key="/BackEnd/Txz" @click="$router.push('/BackEnd/Txz')">
            <span>通行证设置</span>
          </a-menu-item>

          <a-menu-item key="/BackEnd/ItemPrice" @click="$router.push('/BackEnd/ItemPrice')">
            <span>物品价格设置</span>
          </a-menu-item>

          <a-menu-item key="/BackEnd/GameLevel" @click="$router.push('/BackEnd/GameLevel')">
            <span>游戏等级设置</span>
          </a-menu-item>

          <a-menu-item key="/BackEnd/HtmlRegex" @click="$router.push('/BackEnd/HtmlRegex')">
            <span>HTML生成</span>
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub2">
          <span slot="title"><a-icon type="folder-add"/><span>文章</span></span>
          <a-menu-item key="/BackEnd/AddArticle" @click="$router.push('/BackEnd/AddArticle')">
            <span>发布文章</span>
          </a-menu-item>

          <a-menu-item key="/BackEnd/article/ArticleList" @click="$router.push('/BackEnd/article/ArticleList')">
            <span>文章列表</span>
          </a-menu-item>


        </a-sub-menu>


        <a-sub-menu key="sub4">
          <span slot="title"><a-icon type="book"/><span>帮助</span></span>
          <a-menu-item key="/BackEnd/HelperCenter" @click="$router.push('/BackEnd/HelperCenter')">
            <span>文章列表</span>
          </a-menu-item>

          <a-menu-item key="/BackEnd/SendHelper" @click="$router.push('/BackEnd/SendHelper')">
            <span>发布文章</span>
          </a-menu-item>


        </a-sub-menu>

        <a-sub-menu key="sub3">
          <span slot="title"><a-icon type="alert"/><span>日志</span></span>
          <a-menu-item key="/BackEnd/logger" @click="$router.push('/BackEnd/logger')">
            <span>查看日志</span>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="sub5">
          <span slot="title"><a-icon type="usergroup-add"/><span>用户</span></span>
          <a-menu-item key="/BackEnd/userList" @click="$router.push('/BackEnd/userList')">
            <span>用户列表</span>
          </a-menu-item>
          <a-menu-item key="/BackEnd/MonthlyUserList" @click="$router.push('/BackEnd/MonthlyUserList')">
            <span>包月用户列表</span>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="sub6">
          <span slot="title"><a-icon type="wechat"/><span>建议</span></span>
          <a-menu-item key="/BackEnd/AdviceList" @click="$router.push('/BackEnd/AdviceList')">
            <span>用户建议</span>
          </a-menu-item>
        </a-sub-menu>


        <a-sub-menu key="sub7">
          <span slot="title"><a-icon type="shop"/><span>商品</span></span>
          <a-menu-item key="/BackEnd/Goods" @click="$router.push('/BackEnd/Goods')">
            <span>商品列表</span>
          </a-menu-item>
        </a-sub-menu>


        <a-sub-menu key="sub8">
          <span slot="title"><a-icon type="ordered-list"/><span>订单</span></span>
          <a-menu-item key="/BackEnd/order" @click="$router.push('/BackEnd/order')">
            <span>订单列表</span>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="sub9">
          <span slot="title"><a-icon type="ant-design"/><span>素材</span></span>
          <a-menu-item key="/BackEnd/material" @click="$router.push('/BackEnd/material')">
            <span>素材列表</span>
          </a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="sub10">
          <span slot="title"><a-icon type="bug"/><span>黑名单</span></span>
          <a-menu-item key="/BackEnd/BlackList" @click="$router.push('/BackEnd/BlackList')">
            <span>黑名单列表</span>
          </a-menu-item>
        </a-sub-menu>


      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-dropdown v-model="headerPopment">
          <div style="float: right;margin-right: 30px;letter-spacing: 1px;font-size: 15px"> 你好! <a class="log">{{
              $store.getters.Mail
            }}</a>
          </div>
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="$router.push('/')">
              返回首页
            </a-menu-item>
            <a-menu-item key="2" disabled>
              <hr>
            </a-menu-item>
            <a-menu-item key="3" @click="logOut">
              退出登录
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </a-layout-header>

      <a-layout-content style="margin: 0 16px">
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item style="color: blue;font-size: 18px;font-weight: 600">后台</a-breadcrumb-item>
          <a-breadcrumb-item style="color: red;font-size: 18px;font-weight: 600">{{ $route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{ padding: '24px', background: '#fff' }"
             style="min-height: 90%;border-radius: 9px;box-shadow: 1px 1px 3px 1px rgba(0,0,0,0.2);overflow: auto">
          <router-view></router-view>
        </div>
      </a-layout-content>

    </a-layout>

  </a-layout>
</template>
<script>
export default {
  data() {
    return {
      collapsed: false,
      headerPopment: false
    };
  },
  methods: {
    logOut() {
      this.$store.commit("setF5Token", "");
      this.$store.commit("setUsrRole", "");
      this.$store.commit("setAcToken", "");
      this.$store.commit("setIsLogin", "0");
      this.$store.commit("setMail", "");
      this.$router.replace('/');
    },
  }

};
</script>

<style>


.log {
  transition: all .5s;
  color: #00BFA5;
}

.log:hover {
  font-size: 16px;
  color: red;
}
</style>
